import React from "react";
import { useState } from "react";
import { Modal, Carousel, Button, Anchor } from "antd";

import bookBanner from './img/howToBook.png';
import amenTitle from './img/amenities.png';
import amenKit from './img/artboard-kitchen-amenity.png';
import footer from './img/footer.png';
import galleryBtn from './img/gallery-btn.png';
import mbc06 from './img/mbc06.jpg';
import pos01 from './img/pos01.jpg';
import bath01 from './img/bath01.jpg';
import kitchen01 from './img/kitchen01.jpg';
import apt05 from './img/apt05.jpg';
import apt06 from './img/apt06.jpg';
import apt07 from './img/apt07.jpg';
import apt08 from './img/apt08.jpg';
import apt14 from './img/apt14.jpg';
import apt15 from './img/apt15.jpg';


export function BottomArea() {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const onChange = (currentSlide) => {
        // console.log(currentSlide);
    }
    return (
        <div className="footer-area">
            <div className="btn-container">
                <img className="gallery-btn" id="gallery" src={galleryBtn} alt="gallery button" onClick={showModal} />
                <Modal title="Photo Gallery" open={isModalOpen}
                    footer={[
                        <Button type="primary" onClick={handleOk}>
                            OK
                        </Button>
                    ]}>
                    <div>
                        <Carousel afterChange={onChange}>
                            <div>
                                <img className="caro-img" src={mbc06} alt="gallery" />
                            </div>
                            <div>
                                <img className="caro-img" src={pos01} alt="gallery" />
                            </div>
                            <div>
                                <img className="caro-img" src={kitchen01} alt="gallery" />
                            </div>
                            <div>
                                <img className="caro-img" src={apt05} alt="gallery" />
                            </div>
                            <div>
                                <img className="caro-img" src={apt06} alt="gallery" />
                            </div>
                            <div>
                                <img className="caro-img" src={apt07} alt="gallery" />
                            </div>
                            <div>
                                <img className="caro-img" src={apt08} alt="gallery" />
                            </div>
                            <div>
                                <img className="caro-img" src={bath01} alt="gallery" />
                            </div>
                            <div>
                                <img className="caro-img" src={apt14} alt="gallery" />
                            </div>
                            <div>
                                <img className="caro-img" src={apt15} alt="gallery" />
                            </div>
                        </Carousel>
                    </div>
                </Modal>
            </div>
            <div className="how-to-book-banner" id="instructions">
                <img className="booking-banner" src={bookBanner} alt="How To Book" />
            </div>

            <div className="amenity-container" id="amenity">
                <div className="amenity-image-container">
                    <img className="kitchen-amenity" src={amenKit} alt="kitchen-amenities" />
                </div>
                <div className="amenity-text-container">
                    <>
                        <img className="amenities-title" src={amenTitle} alt="amenity title" />
                    </>
                    <div className="amenity-lists">
                        <>
                            <p className="amenity-list">
                                - 2 office work desks<br />- 1 large meeting table<br />- \nWaiting seating area<br />- Wi-Fi<br />- 1 landline<br />- Breakroom with Kitchen<br />- Washer/Dryer combo<br />- Coffee station
                            </p></>
                        <>
                            <p className="amenity-list">
                                - Nap room with wardrobe and dresser<br />- Pull over sofa<br />- 2 Murphy bed cabinetsonal<br />- 1 permit tag for street meter parking<br />- 1 permit tag for beach parking<br />- 1 car pass for national parks<br />- Pets allowed<br />- Ceiling fans and space heaters
                            </p>
                        </>
                    </div>
                </div>
            </div>

            <div className="footer-banner" id="footer">
                <p className="navi-bar-bottom">
                    Contact Information: <br />
                    Phone: <br />
                    Email:
                </p>
            </div>
        </div>
    )
}
