const domain = "http://34.30.109.228:8080";
// const savedGuestAuthToken = "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ6YWNrX2d1ZXN0IiwiZXhwIjoxNjg5Mjc3MTU1LCJpYXQiOjE2ODkxOTA3NTV9.Xl6PlKjL2TtyiSe0ypbuWxfw0iXNSHMQYBTTE2LLcNw"

export const getReservations = () => {
    // const authToken = savedGuestAuthToken;
    const listReservationsUrl = `${domain}/reservationDates`;

    return fetch(listReservationsUrl, {
        // headers: {
        //     Authorization: `Bearer ${authToken}`,
        // },
    }).then((response) => {
        if (response.status !== 200) {
            throw Error("Fail to get reservation list");
        } 
        return response.json();
    });
}; 

export const bookStay = (data) => {
    // const authToken = savedGuestAuthToken;
    const bookStayUrl = `${domain}/reservations`;

    return fetch(bookStayUrl, {
        method: "POST",
        headers: {
            // Authorization: `noAuth`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    }).then((response) => {
        if (response.status !== 200) {
            throw Error("Fail to book reservation");
        }
    });
}

export const getCheckinDates = () => {
    // const authToken = savedGuestAuthToken;
    const listReservationsUrl = `${domain}/reservations/checkin`;

    return fetch(listReservationsUrl, {
        // headers: {
        //     Authorization: `Bearer ${authToken}`,
        // },
    }).then((response) => {
        if (response.status !== 200) {
            throw Error("Fail to get reservation dates");
        } 
        return response.json();
    });
}; 

export const getCheckoutDates = () => {
    // const authToken = savedGuestAuthToken;
    const listReservationsUrl = `${domain}/reservations/checkout`;

    return fetch(listReservationsUrl, {
        // headers: {
        //     Authorization: `Bearer ${authToken}`,
        // },
    }).then((response) => {
        if (response.status !== 200) {
            throw Error("Fail to get reservation dates");
        } 
        return response.json();
    });
}; 

export const getConfirmedCheckinDates = () => {
    // const authToken = savedGuestAuthToken;
    const listReservationsUrl = `${domain}/reservations/confirmed_checkin`;

    return fetch(listReservationsUrl, {
        // headers: {
        //     Authorization: `Bearer ${authToken}`,
        // },
    }).then((response) => {
        if (response.status !== 200) {
            throw Error("Fail to get reservation dates");
        } 
        return response.json();
    });
}; 

export const getConfirmedCheckoutDates = () => {
    // const authToken = savedGuestAuthToken;
    const listReservationsUrl = `${domain}/reservations/confirmed_checkout`;

    return fetch(listReservationsUrl, {
        // headers: {
        //     Authorization: `Bearer ${authToken}`,
        // },
    }).then((response) => {
        if (response.status !== 200) {
            throw Error("Fail to get reservation dates");
        } 
        return response.json();
    });
}; 

export const sendConfirmationEmail = (data) => {
    const emailApiUrl = `${domain}/send-email`; 

    return fetch(emailApiUrl, {
        method: "POST",
        headers: {
        },
        body: data,
    }).then((response) => {
        if (response.status !== 200) {
            throw Error("Fail to book reservation");
        }
    });
}

export const sendVerificationCode = (data) => {
    const emailApiUrl = `${domain}/send-vcode`; 

    return fetch(emailApiUrl, {
        method: "POST",
        headers: {
        },
        body: data,
    }).then((response) => {
        if (response.status !== 200) {
            throw Error("Fail to send verification code");
        }
    });
}