import "./App.css";
import React from "react";
import { Layout, Anchor } from "antd";
import { MainPage } from './components/MainPage';
import { CheckOut } from "./components/CheckOut";
import { BottomArea } from "./components/BottomArea";
import cpaLogo from './components/img/cpa-logo.png';
import { Sticky } from "semantic-ui-react";

const { Content, Header, Footer } = Layout;

function App() {
    return (
        <Layout style={{ backgroundColor: "#fff" }}>
            <Header id="header" style={{ position: "sticky", top: 0, zIndex: 1, display: "flex", justifyContent: "space-between", backgroundColor: "#fff", height: "100px" }}>
                <>
                    <img className="cpa-logo" src={cpaLogo} alt="cpa logo" />
                </>
                <>
                    <Anchor
                        style={{ position: 'relative', top: "40px"}}
                        affix={false}
                        direction="horizontal"
                        offsetTop={150}
                        items={[
                            {
                                key: 'home',
                                href: '#home', 
                                title: 'Home'
                            },
                            {
                                key: 'gallery',
                                href: '#gallery', 
                                title: 'Gallery'
                            },
                            {
                                key: 'instructions',
                                href: '#instructions',
                                title: 'Instructions'
                            },
                            {
                                key: 'amenity',
                                href: '#amenity',
                                title: 'Amenity'
                            },
                            {
                                key: 'things-to-do',
                                href: '#things-to-do',
                                title: 'Things to Do'
                            }, {
                                key: 'contacts',
                                href: '#footer', 
                                title: 'Contacts'
                            }
                        ]}
                    />
                </>
            </Header>
            <Content style={{ marginBottom: 100 }}>
                <MainPage />
                {/* <CheckOut /> */}
                <BottomArea />
            </Content>
        </Layout>
    )

}

export default App;
