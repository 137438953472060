
import React from "react";
import { useState } from "react";
import { Form, Button, InputNumber, DatePicker, Select, Col, Row, Spin, message, Modal, Carousel } from 'antd';
import { BottomArea } from "./BottomArea";
import dayjs from "dayjs";

import banner from './img/banner.png';
import bookingBanner from './img/booking-banner.png';
import bookingBtnTop from './img/bookBtnTop.png';
import loft1 from './img/loft1.png';
import loft2 from './img/loft2.png';
import loft3 from './img/loft3.png';
import loft4 from './img/loft4.png';
import apt1 from './img/apt1.png';
import apt2 from './img/apt2.png';
import apt3 from './img/apt3.png';
import apt4 from './img/apt4.png';
import loftTitle from './img/cooperate-loft.png';
import aptTitle from './img/apartment1.png';

import { bookStay, getReservations, getCheckinDates, getCheckoutDates, getConfirmedCheckinDates, getConfirmedCheckoutDates } from "../utils";
import { CheckOut } from './CheckOut';

var loftDiscription = "Bright and newly renovated loft with sky windows. A small dash to the beach and a few strolls to everything there is to see in Capitola Village. And a quiet “writer” loft to create or a tuck away work space for business and clients meeting.";
var aptDiscription = "Bright and newly renovated loft with sky windows. A small dash to the beach and a few strolls to everything there is to see in Capitola Village. And a quiet “writer” loft to create or a tuck away work space for business and clients meeting.";
export var totalPrice = 0;
var daysReserved = 0;

export var checkinDate = '';
export var checkoutDate = '';
export var room = '';
let selectedRoom = ''; 

function calculatePrice() {
    totalPrice = Math.round((daysReserved * 80) * 100) / 100;
}

export class SearchStays extends React.Component {

    state = {
        uploading: false,
        data: [],
        bookingHidden: false,
        checkin_list: [],
        checkout_list: [],
        confirmed_checkin: [],
        confirmed_checkout: [],
    }

    hideBookingArea = () => {
        this.setState({
            bookingHidden: true,
        });
        calculatePrice();
    }

    loadResDates = async () => {
        try {
            const resp = await getReservations();
            this.setState({
                data: resp,
            });
            const resp1 = await getCheckinDates();
            this.setState({
                checkin_list: resp1,
            });
            const resp2 = await getCheckoutDates();
            this.setState({
                checkout_list: resp2,
            });
            const resp3 = await getConfirmedCheckinDates();
            this.setState({
                confirmed_checkin: resp3,
            });
            const resp4 = await getConfirmedCheckoutDates();
            this.setState({
                confirmed_checkout: resp4,
            });
        } catch (error) {
            message.error("can't get dates")
        }
    }

    handleSubmit = async (values) => {
        this.setState({
            uploading: true,
        })
        try {
            await bookStay({
                checkin_date: values.checkin_date.format("YYYY-MM-DD"),
                checkout_date: values.checkout_date.format("YYYY-MM-DD"),
                stay: {
                    id: values.stay_id,
                },
            });
            checkinDate = values.checkin_date.format("MM-DD-YYYY");
            checkoutDate = values.checkout_date.format("MM-DD-YYYY")
            room = values.stay_id;

            daysReserved = (values.checkout_date - values.checkin_date) / (24 * 3600 * 1000);
            message.success("Successfully booked this room");
            this.hideBookingArea();
        } catch (error) {
            message.error("Can't reserve this room");
        } finally {
            this.setState({
                uploading: false,
            });
        }
    };

    render() {
        let checkinList = this.state.checkin_list;
        let checkoutList = this.state.checkout_list;
        let confirmedCheckinList = this.state.confirmed_checkin;
        let confirmedCheckoutList = this.state.confirmed_checkout;



        const handleOnChange = (value) => {
            selectedRoom = value; 
        }

        if (this.state.bookingHidden === false) {
            return (
                <div className="booking-area">
                    <div className="booking-banner-contaner">
                        <img className="booking-banner-img" src={bookingBanner} alt="booking banner" />
                    </div>

                    <div className="booking-form-container">
                        <Form className="booking-form" layout="vertical" autoComplete="off" onFinish={this.handleSubmit}>
                            <>
                                <Row justify="space-between">
                                    <Col span={8}>
                                        <Form.Item className="check-in" name="checkin_date" label="CHECK-IN" style={{ span: 6 }}>
                                            <DatePicker
                                                format="YYYY-MM-DD"
                                                onClick={() => this.loadResDates()}
                                                disabledDate={(current) => {
                                                    return current < dayjs().endOf('day');
                                                }}
                                                cellRender={(current) => {
                                                    const style = {};
                                                    for (let i = 0; i < checkinList.length; i++) {
                                                        if (current >= dayjs(checkinList[i]) && current <= dayjs(checkoutList[i])) {
                                                            style.border = "1px solid #a0a0a0";
                                                            style.borderRadius = "50%";
                                                            style.backgroundColor = "#ffe000";
                                                        }
                                                    }
                                                    for (let i = 0; i < confirmedCheckinList.length; i++) {
                                                        if (current >= dayjs(confirmedCheckinList[i]) && current <= dayjs(confirmedCheckoutList[i])) {
                                                            style.border = "1px solid #202020";
                                                            style.borderRadius = "50%";
                                                            style.backgroundColor = "#ff4040";
                                                        }
                                                    }
                                                    return (
                                                        <div className="ant-picker-cell-inner" style={style}>
                                                            {current.date()}
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item className="check-out" name="checkout_date" label="CHECK-OUT">
                                            <DatePicker
                                                format="YYYY-MM-DD"
                                                onClick={() => this.loadResDates()}
                                                disabledDate={(current) => {
                                                    return current < dayjs().endOf('day');
                                                }}
                                                cellRender={(current) => {
                                                    const style = {};
                                                    for (let i = 0; i < checkinList.length; i++) {
                                                        if (current >= dayjs(checkinList[i]) && current <= dayjs(checkoutList[i])) {
                                                            style.border = "1px solid #a0a0a0";
                                                            style.borderRadius = "50%";
                                                            style.backgroundColor = "#ffe000";
                                                        }
                                                    }
                                                    for (let i = 0; i < confirmedCheckinList.length; i++) {
                                                        if (current >= dayjs(confirmedCheckinList[i]) && current <= dayjs(confirmedCheckoutList[i])) {
                                                            style.border = "1px solid #202020";
                                                            style.borderRadius = "50%";
                                                            style.backgroundColor = "#ff4040";
                                                        }
                                                    }
                                                    return (
                                                        <div className="ant-picker-cell-inner" style={style}>
                                                            {current.date()}
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row justify="space-between">
                                    <Col span={8}>
                                        <Form.Item className="room-selection" name="stay_id" label="ROOMS" style={{ maxWidth: 175 }}>
                                            <Select onChange={handleOnChange}>
                                                <Select.Option value={1}>Loft</Select.Option>
                                                <Select.Option value={2}>Suite 1</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item className="guests" name="guest" label="GUESTS" wrapperCol={12} style={{ maxWidth: 175 }}>
                                            <InputNumber />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                            <Button type="primary" htmlType="submit" loading={this.state.uploading} style={{ width: "50%", margin: "auto" }} >
                                Submit
                            </Button>

                        </Form>
                    </div>

                    {/* Rooms display */}
                    <div className="room-display" style={{paddingBottom: "50px"}}>
                        <Row justify="center">
                            <Col span={4}>
                                <img className="room-imgs" src={loft2} alt="loft" onClick={() => {
                                    selectedRoom = '1'; 
                                }} />
                                <p className="room-labels">Loft</p>
                            </Col>
                            <Col span={4}>
                                <img className="room-imgs" src={apt1} alt="loft" onClick={() => {
                                    selectedRoom = '2'; 
                                }} />
                                <p className="room-labels">Apartment 1</p>
                            </Col>
                        </Row>
                    </div>
                </div>
            )
        } else {
            return (
                <CheckOut />
            )
        }
    }
}

export function MainPage() {
    const [introVisible, setIntroVisible] = useState(true);

    const hideIntroArea = () => {
        setIntroVisible(!introVisible);
    }

    return (
        <div className="body">
            <div className="booking-area">

                {/* Area need to be altered in booking page  */}
                {introVisible && <div className="intro-area">
                    <div className="title-banner" id="home">
                        <img
                            className="close-up-soft-wave-sea-sandy-beach"
                            src={banner}
                            alt="bg-banner" />
                    </div>
                    <>
                        <img className="booking-btn-top" src={bookingBtnTop} alt="booking botton top"
                            onClick={hideIntroArea}
                        />
                    </>

                    <div className="loft-container">
                        <div className="loft-text-container">
                            <>
                                <img className="loft-title" src={loftTitle} alt="loft title" />
                            </>
                            <>
                                <h1 className="loft-subtitle">In Capitola Village</h1>
                                <p className="loft-discription">{loftDiscription}</p>
                            </>
                        </div>
                        <div className="loft-image-container">
                            <img className="loft-image" src={loft1} alt="loftImage" />
                            <img className="loft-image" src={loft2} alt="loftImage" />
                            <img className="loft-image" src={loft3} alt="loftImage" />
                            <img className="loft-image" src={loft4} alt="loftImage" />
                        </div>
                    </div>

                    <div className="apt-container">
                        <div className="apt-image-container">
                            <img className="apt-image" src={apt1} alt="loftImage" />
                            <img className="apt-image" src={apt2} alt="loftImage" />
                            <img className="apt-image" src={apt3} alt="loftImage" />
                            <img className="apt-image" src={apt4} alt="loftImage" />
                        </div>
                        <div className="apt-text-container">
                            <>
                                <img className="apt-title" src={aptTitle} alt="apt title" />
                            </>
                            <>
                                <p className="apt-discription">{aptDiscription}</p>
                            </>
                        </div>
                    </div>
                </div>}
                {!introVisible && <SearchStays />}
            </div>
        </div>
    )
}


export default MainPage; 
