import React from "react";

import { useState } from "react";
import { Col, Row, message, Alert } from 'antd';
import { Form, Button, Message } from 'semantic-ui-react';
import { sendConfirmationEmail, sendVerificationCode } from "../utils";
import { usStates } from "./states";
import { totalPrice, checkinDate, checkoutDate, room } from "./MainPage";

import bookingBanner from './img/booking-banner.png';
import confBtn from './img/conf-btn.png'; 

class AlertError extends React.Component {
    render() {
        return (
            <div>
                <Alert type="error" message="Email Not Verified" />
            </div>
        )
    }
}

export class CheckOut extends React.Component {

    getRandomInt = (max) => {
        return Math.floor(Math.random() * max);
    }

    randomInt = this.getRandomInt(999999);


    emailInput = "";
    codeInput = "";
    couponInput = "";

    handleSendEmail = async (values) => {
        const formData = new FormData();
        formData.append("email", this.emailInput);
        formData.append("checkin_date", checkinDate);
        formData.append("checkout_date", checkoutDate);
        formData.append("room", room);

        try {
            await sendConfirmationEmail(formData);
            message.success("Reservation Submitted. Confirmation Email Sent.");
        } catch (error) {
            message.error("Faild to Reserve");
        } finally {

        }
    }

    handleSendVerificationCode = async (values) => {
        const formData = new FormData();
        formData.append("email", this.emailInput);
        formData.append("code", this.randomInt);
        try {
            await sendVerificationCode(formData);
            message.success("Verification Code Sent");
        } catch (error) {
            message.error("Faild send code");
        } finally {

        }
    }

    state = {
        coupon: "",
        submittedCoupon: "",
        price: totalPrice,
        email: "",
        submittedEmail: "",
        verified: false,
        couponValidated: false,
        invalidCoupon: false,
        showError: false
    };

    handleCodeChange = (e, { codeIn, value }) => {
        this.setState({ [codeIn]: value });
        this.codeInput = value;
    };

    handleEmailChange = (e, { emailIn, value }) => {
        this.setState({ [emailIn]: value })
        this.emailInput = value;
    };

    handleVerification = () => {
        if (this.codeInput == this.randomInt) {
            this.setState({ verified: true })
            message.success("Email Verified");
        } else {
            message.error("Incorrect Verification Code");
        }
    }

    handleCouponChange = (e, { couponIn, value }) => {
        this.setState({ [couponIn]: value })
        this.couponInput = value;
    };

    handleCouponSubmit = () => {
        const { coupon } = this.state;
        this.setState({ submittedCoupon: coupon });
    }

    handleValidateCoupon = () => {
        if (this.couponInput == 'COMMCPA') {
            this.setState({ price: 0, couponValidated: true, invalidCoupon: false });
            console.log("Free")
        } else {
            this.setState({ couponValidated: false, invalidCoupon: true });
        }
    }


    render() {
        const { couponIn, codeIn, emailIn } = this.state

        return (
            <>
                <>
                    <img className="booking-banner-img" src={bookingBanner} alt="booking banner" />
                </>
                <div className="checkout-container">
                    <div style={{ backgroundColor: "#a0f0e0", height: "40px", margin: "auto" }}>
                        <h2 style={{ marginLeft: 50, paddingTop: 4 }}>Your Personal Information</h2>
                    </div>
                    <div style={{ margin: "auto", width: "90%" }}>
                        <Form size="small">
                            <Row style={{ marginTop: "30px", gap: "30px" }}>
                                <Col span={7}>
                                    <Form.Input
                                        fluid
                                        name="firstname"
                                        label='First Name'
                                        placeholder='John'
                                    />
                                </Col>
                                <Col span={7}>
                                    <Form.Input
                                        fluid
                                        name='lastname'
                                        label='Last name'
                                        placeholder='Smith'
                                    />
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "30px", gap: "30px" }}>
                                <Col span={7}>
                                    <Form.Input
                                        fluid
                                        name='email'
                                        label='Email'
                                        placeholder='xyz@example.com'
                                        type='email'
                                        value={emailIn}
                                        onChange={this.handleEmailChange}
                                    />
                                </Col>
                                <Col span={4}>
                                    <Form.Input
                                        name='vcode'
                                        label="Verification Code"
                                        placeholder="Enter Code"
                                        value={codeIn}
                                        type="number"
                                        onChange={this.handleCodeChange} />
                                </Col>
                                <Col style={{ marginLeft: "-20px" }}>
                                    <Button primary style={{ marginTop: 25 }} onClick={this.handleSendVerificationCode}>Send Verification Code</Button>
                                </Col>
                                <Col style={{ marginLeft: "-20px" }}>
                                    <Button primary style={{ marginTop: 25 }} onClick={this.handleVerification}>Verify Email</Button>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "30px", gap: "30px" }}>
                                <Col span={7}>
                                    <Form.Input
                                        name='street'
                                        label='Address'
                                        placeholder='201 Monterey St'
                                    />
                                </Col>
                                <Col span={5}>
                                    <Form.Input
                                        name='town_city'
                                        label='Town/City'
                                        placeholder='Las Vegas'
                                    />
                                </Col>
                                <Col span={5}>
                                    <Form.Select
                                        label='State'
                                        placeholder='Search ...'
                                        name='county_state'
                                        search
                                        fluid
                                        options={usStates}
                                    />
                                </Col>
                                <Col span={4}>
                                    <Form.Input
                                        type='number'
                                        name='postal_zip_code'
                                        label='Zip/Postal'
                                        placeholder='00000'
                                    />
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col style={{ margin: "auto", top: 30 }}>
                                    <Form.Field>
                                        <Button primary type="submit" onClick={this.handleSendEmail} disabled={!this.state.verified} >
                                            Submit
                                        </Button>
                                    </Form.Field>
                                </Col>
                            </Row> */}
                        </Form>
                    </div>

                    <div style={{ backgroundColor: "#a0f0e0", height: "40px", margin: "auto", marginTop: "30px" }}>
                        <h2 style={{ marginLeft: 50, paddingTop: 4 }}>Coupon</h2>
                    </div>

                    <div style={{ margin: "auto", width: "90%" }}>
                        <Row style={{ marginTop: "30px" }}>
                            <Form
                                className="coupon-form"
                                onSubmit={this.handleCouponSubmit}
                                success={this.state.couponValidated}
                                error={this.state.invalidCoupon}
                            >
                                <Form.Group>
                                    <Form.Input
                                        name='coupon'
                                        label='Coupon'
                                        value={couponIn}
                                        onChange={this.handleCouponChange}
                                    />
                                    <Message
                                        success
                                        header='Coupon Applied'
                                    />
                                    <Message
                                        error
                                        header='Invalid Coupon'
                                    />
                                    <Form.Button content="Validate Coupon" onClick={this.handleValidateCoupon} style={{ marginTop: "25px" }} />
                                </Form.Group>
                            </Form>
                        </Row>
                    </div>
                    {/* <div className="confirm-reservation">
                        <Button onClick={() => {
                            message.success("Reservation Success")
                        }}>
                            Confirm This Reservation
                        </Button>
                    </div> */}
                    <div className="price-submit">
                        <h3 style={{ marginLeft: 200, marginTop: "13px" }}>{"Total Price: $ " + this.state.price}</h3>
                        <img className="reserve-btn" src={confBtn} alt="confirm button" style={{ width: "25%" }}
                            onClick={() => {
                                if (this.state.verified) {
                                    this.setState({ error: true });
                                    this.handleSendEmail();
                                } else {
                                    this.setState({ error: true });
                                }
                            }} />
                    </div>
                    <div> 
                        {this.state.error ? 
                        <Alert 
                        type="error" 
                        message="Email Not Verified" 
                        style={{width: "25%", marginLeft: "auto", marginRight: '0px'}} 
                        /> : 
                        <></>}
                    </div>
                    <div className="back-btn">
                        <Button onClick={() => { window.location.reload() }} >
                            Back to Fronts Page
                        </Button>
                    </div>

                </div>
            </>
        )
    };
}
